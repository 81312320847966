/**
 * @author Akshay Kumar Singh
 * @file WOReviewerAns Component
 * @flow
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { WO_QUES_DATA } from '../../../../redux/actions/common_action_types';
import {
    getPostDataWithType,
    getPostDataWithAction,
    downloadFile
} from '../../../../redux/actions/common_action';
import CONFIG from '../../../../configuration';
import AlertModal from '../../../../shared/modals/AlertModal';
import { getAuditId } from '../../../../shared/utils/helper.util';
import { getData } from '../../../../shared/utils/storage.util';
import { STRINGS } from '../../../../shared/constants/data';
import { spinnerService } from '../../../../shared/services/spinner.service';
import { CallApi } from '../../../../shared/services/api.service';
import ImagePrevew from '../../../../shared/modals/ImagePrevew';
import ReasonModal from '../../../../shared/modals/ReasonModal';
import PreviewModal from '../../../../shared/modals/WOPreviewModal';
import IdleTimerComponent from '../../../Common/IdleTimeCheck/IdleTimerComponent';
import { checkFileType } from '../../../../shared/utils/helper.util';
import { getRoleBasedRoute } from '../../../../shared/utils/helper.util';
import { logoutUnauthorizedUser } from '../../../../shared/utils/auth.util';
import LANGUAGECONST from '../../../../shared/constants/language';

class WOReviewerAnsComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskInfo: {},
            QuesList: [],
            woOptionsArr: [],
            heading: [],
            allQuesData: [],
            currentTabName: null,
            showImageModel: false,
            showPreviewModel: false,
            quesIndex: null,
            imgObj: null,
            imgIndex: null,
            openReasonModal: false,
            checkDataChange: false,
            submitObj1: {},
            submitObj2: {},
            confirmation: false,
            quesValidation: ['Pass', 'Major NC', 'Minor NC'],
            filterVal: '',
            isSubmitClicked: false,
            filterArr: [
                'WORK ORDERS - PM',
                'WORK ORDERS - REPAIR',
                'WORK ORDERS - REBUILD',
                'WORK ORDERS - WARRANTY'
            ]
        };
    }

    componentDidMount = () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { taskInfo } = this.props;
        let { task_id } = taskInfo;
        this.setState({
            taskInfo: taskInfo || {}
        });
        let obj = { task_id, is_save: 0 };
        this.props.getWoData(
            CONFIG.getWOQues,
            obj,
            WO_QUES_DATA,
            lang.STRINGS.FetchingData,
            this,
            language
        );
        this.getWoOptions(taskInfo);
        // this.interval = setInterval(()=>{
        //   this.submitAns(false, true);
        // },15000);
    };

    componentWillReceiveProps = (nextProps) => {
        let { allQuesData, QuesList, taskInfo } = nextProps;
        this.setState({
            allQuesData: allQuesData || [],
            QuesList: QuesList || []
        });
        this.getHeading(taskInfo || {}, QuesList || []);
    };

    // componentWillUnmount = () => {
    //   clearInterval(this.interval);
    // }
    /**
     * @description This function sets value of tabs according task questions.
     * @param taskInfo It has info regarding task
     * @param QuesList It consists list of all questions regarding that task
     */
    getHeading = (taskInfo, QuesList) => {
        let { currentTabName } = this.state;
        let allHeading = QuesList.map((quesObj) => quesObj['long_heading']);
        let heading = allHeading.filter(
            (filterHeading, index) => allHeading.indexOf(filterHeading) === index
        );
        this.setState({
            heading,
            taskInfo,
            QuesList,
            currentTabName: currentTabName ? currentTabName : heading[0]
        });
    };

    /**
     * @description This function set values in data object according to their questions
     * @param quesId It has the value of question id whose value to be set.
     */
    handleChange = (event, quesId = null) => {
        let { allQuesData, QuesList } = this.state;
        let { id, name, value } = event.target;
        if (quesId == null) {
            allQuesData[id][name] = value;
            this.setState({ allQuesData, checkDataChange: true });
        } else {
            QuesList.map((data) => {
                if (data.ques_id == quesId) {
                    if (name == 'validations') {
                        data.validation = value;
                    } else {
                        if (name == 'audit_comments') {
                            data.auditor_comments = value;
                        } else if (name == 'review_comments') {
                            data.reviewer_comments = value;
                        } else {
                            data.value = value;
                        }
                    }
                }
            });
            this.setState({ QuesList, checkDataChange: true }, () => {});
        }
    };

    /**
     * @description This function set character limit on fields
     */
    handleChangeWithLength = (event, ques_id = null) => {
        let { value } = event.target;
        if (value.length <= 400) {
            this.handleChange(event, ques_id);
        }
    };

    /**
     * @description This function set the value of work order type to be filtered to show work orders in WO - Reference tab
     */
    handleFilter = (event) => {
        let { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    /**
     * @description This function fetch data for descripancy drop-down.
     * @param taskInfo It has info regarding task.
     */
    getWoOptions = (taskInfo) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { task_id } = taskInfo;
        this.setState({
            taskInfo: taskInfo || {}
        });
        let woOptionsObj = { task_id };
        spinnerService.show('Oval', lang.STRINGS.FetchingData);
        CallApi('POST', CONFIG.getWoOptions, {}, woOptionsObj, '', 'woOptions', this, language);
    };

    /**
     * @description getWoOptions API callback function
     * @param response This is the response structure received from API
     */
    woOptions = (response) => {
        if (response.success) {
            this.setState({
                woOptionsArr: response.response
            });
        }
    };

    /**
     * @description This function validates the values of data object on submit
     * @param dataArr It has data objects to be validated
     */
    validateData = (dataArr) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        this.setState({
            isSubmitClicked: true
        });
        let error = null;
        let { checkDataChange } = this.state;
        // if (!checkDataChange) {
        for (let data = 0; data < dataArr.length; data++) {
            if (
                (dataArr[data].long_heading == 'GENERAL OBSERVATIONS' &&
                    dataArr[data].wo_id === 34) ||
                dataArr[data].long_ques.trim() === 'Value %'
            ) {
                dataArr[data].validation = 'Major NC';
            }
            if (
                dataArr[data].long_heading != 'WORK ORDERS - PARTS CONSUMPTION' &&
                dataArr[data].long_heading != 'WORK ORDERS - REFERENCE'
            ) {
                if (
                    dataArr[data].long_heading != 'GENERAL OBSERVATIONS' &&
                    dataArr[data].is_display != 1
                ) {
                    if (
                        !!dataArr[data].validation &&
                        dataArr[data].validation != 'Pass' &&
                        (!dataArr[data].auditor_comments || !dataArr[data].reviewer_comments)
                    ) {
                        this.setState(
                            {
                                currentTabName: dataArr[data].long_heading
                            },
                            () => {}
                        );
                        return (error = `${lang.STRINGS.IMPACTRECOMMENDATIONREQ} ${dataArr[data].long_ques}`);
                    } else if (
                        !dataArr[data].value &&
                        dataArr[data].long_heading === 'WORK ORDERS - WARRANTY' &&
                        (dataArr[data].wo_id === 19 ||
                            dataArr[data].long_ques.trim() === 'Number of Claims')
                    ) {
                        this.setState(
                            {
                                currentTabName: dataArr[data].long_heading
                            },
                            () => {}
                        );
                        return (error = `${lang.STRINGS.QUESTIONNAIREREQ} ${dataArr[data].long_ques}`);
                    }
                } else {
                    if (dataArr[data].long_heading == 'GENERAL OBSERVATIONS') {
                        if (
                            (dataArr[data].wo_id === 31 ||
                                dataArr[data].long_ques.trim() ===
                                    'Total mechanic hours worked in proposed time period') &&
                            !dataArr[data].value
                        ) {
                            this.setState({ currentTabName: dataArr[data].long_heading });
                            return (error = `${lang.STRINGS.VALUEREQ} ${dataArr[data].long_ques}`);
                        } else if (
                            (dataArr[data].wo_id === 34 ||
                                dataArr[data].long_ques.trim() === 'Value %') &&
                            !dataArr[data].validation
                        ) {
                            dataArr[data].validation = 'Major NC';
                            // else if (!dataArr[data].validation) {
                            //   this.setState({
                            //     currentTabName: dataArr[data].long_heading
                            //   }, () => { })
                            //   return error = `Please fill out the Questionnaire for ${dataArr[data].long_ques}`;
                            // }
                        } else if (
                            (dataArr[data].wo_id === 34 ||
                                dataArr[data].long_ques.trim() == 'Value %') &&
                            (!dataArr[data].auditor_comments || !dataArr[data].reviewer_comments)
                        ) {
                            this.setState(
                                {
                                    currentTabName: dataArr[data].long_heading
                                },
                                () => {}
                            );
                            return (error = `${lang.STRINGS.IMPACTRECOMMENDATIONREQ} ${dataArr[data].long_ques}`);
                        }
                    }
                }
            } else if (
                dataArr[data].long_heading === 'WORK ORDERS - PARTS CONSUMPTION' &&
                (dataArr[data].wo_id === 26 ||
                    dataArr[data].long_ques.trim() == 'Quantity %' ||
                    dataArr[data].wo_id === 30 ||
                    dataArr[data].long_ques.trim() == 'Value %')
            ) {
                this.setState(
                    {
                        currentTabName: dataArr[data].long_heading
                    },
                    () => {}
                );
                // if (!(dataArr[data].validation)) {
                //   return error = `Please fill out the Validation for ${dataArr[data].long_ques}`;
                // } else
                if (!!dataArr[data].validation && dataArr[data].validation != 'Pass') {
                    if (!dataArr[data].auditor_comments || !dataArr[data].reviewer_comments) {
                        return (error = `${lang.STRINGS.IMPACTRECOMMENDATIONREQ} ${dataArr[data].long_ques}`);
                    }
                }
            }
        }
        // } else {
        //   return error = `Please save before submit.`
        // }
        return error;
    };

    /**
     * @description This function formats data
     * @param dataArr It has data objects to be formatted
     */
    formatData = (dataArr) => {
        let data = dataArr.map((data) => {
            let {
                order_id,
                status,
                validation,
                comments,
                reviewer_comments,
                long_heading,
                is_save
            } = data;
            return {
                order_id,
                status,
                validation,
                comments,
                reviewer_comments,
                long_heading,
                is_save
            };
        });
        return data;
    };

    /**
     * @description This function calls on save and submit data. On save it show preview of
     * data and on submit that task is being assigned to the reviewer. It also get called after being
     * idle for 5 minutes and saves the data only without preview.
     * @param isSubmit It has boolean value to check if the function is called to save or submit the data
     * @param isIdle It has boolean value to check if function is call due to idle situation for 5 min or not
     */
    submitAns = async (isSubmit, isIdle = false) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { userData } = getData('loginData');
        let { taskInfo, allQuesData, QuesList, confirmation } = this.state;
        let { task_id, task_status, reviewer_flag, audit_id } = taskInfo;
        let { role_name } = userData;
        let error = isSubmit
            ? this.validateData(QuesList)
            : this.setState({ checkDataChange: false });
        if (error) {
            return AlertModal.showAlert(error);
        } else {
            let data = {
                task_id,
                task_status: isSubmit ? task_status + 1 : task_status,
                ques: this.formatData(allQuesData),
                isEmail: false,
                isAuditor: false
            };
            let taskQuesData = {
                task_id,
                asset_type_id: getAuditId(taskInfo.task_name),
                ques: QuesList,
                audit_id,
                task_status: isSubmit ? task_status + 1 : task_status,
                reviewer_flag: reviewer_flag ? reviewer_flag : 0,
                ar_reviewer_flag: role_name == 'plant manager' && task_status == 2 ? 1 : 0,
                isEmail: isSubmit ? true : false,
                isTaskCompleted: 0
            };
            if (!isSubmit) {
                let route = isSubmit ? getRoleBasedRoute() : 'SAVE';
                spinnerService.show('Oval', lang.STRINGS.SAVINGDATA);
                await CallApi(
                    'POST',
                    CONFIG.addTaskQues,
                    {},
                    taskQuesData,
                    '',
                    'addTaskdata',
                    this,
                    language
                );
                await this.props.submitAns(
                    CONFIG.addWOQues,
                    data,
                    !isIdle ? route : 'idle',
                    lang.STRINGS.SubmittingData,
                    this,
                    language
                );
                this.props.getQuestionData();
                if (!isIdle) {
                    this.showHidePreviewModel();
                }
            } else {
                this.setState({ submitObj1: taskQuesData, submitObj2: data }, () => {
                    if (!confirmation) {
                        AlertModal.showAlert(lang.STRINGS.AcceptDeclaration);
                    } else {
                        this.onSubmit();
                    }
                });
            }
        }
    };

    /**
     * @description This function saves data of work order reviewer answer data
     */
    onSubmit = async () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        const { submitObj1 } = this.state;
        spinnerService.show('Oval', lang.STRINGS.SubmittingData);
        await CallApi(
            'POST',
            CONFIG.addTaskQues,
            {},
            submitObj1,
            '',
            'addTaskdata',
            this,
            language
        );
    };

    /**
     * @description This function is addTaskQues API callback function and also submits the work order card data too.
     * @param response This is the response structure received from API
     */
    addTaskdata = (response) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        const { submitObj2 } = this.state;
        let route = getRoleBasedRoute();
        if (submitObj2.task_status == 3) {
            if (response.success) {
                this.props.submitAns(
                    CONFIG.addWOQues,
                    submitObj2,
                    route,
                    lang.STRINGS.SubmittingData,
                    this,
                    language
                );
            }
        }
    };

    /**
     * @description This function uploads the image.
     * @param data It consists data of image file.
     */
    uploadImage = async (index, data) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { allQuesData } = this.state;
        spinnerService.show('Oval', lang.STRINGS.UploadingImage);
        try {
            const config = {
                headers: { authorization: `Bearer ${getData('token')}`, language: language }
            };
            let response = await Axios.post(CONFIG.uploadQuesImage, data, config);
            spinnerService.hide('Oval');
            if (response && response.data && response.data.responseCode == 200) {
                allQuesData[index]['url'] = allQuesData[index]['url']
                    ? allQuesData[index]['url'].concat(response.data.response?.url)
                    : response.data.response?.url;
                this.setState({ allQuesData });
            }
            if (response && response.data && response.data.responseCode == 401) {
                logoutUnauthorizedUser(this, response.data.message);
            } else {
                AlertModal.showAlert(response.data.message);
            }
        } catch (error) {
            spinnerService.hide('Oval');
            AlertModal.showAlert(error.message);
        }
    };

    /**
     * @description This function handle images data.
     * @param data It consists data of image file.
     */
    handleFile = (event, data) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { id, name, files } = event.target;
        let { taskInfo } = this.state;
        let { task_id } = taskInfo;
        let { url } = data;
        if (files.length && !checkFileType(files[0].type)) {
            return AlertModal.showAlert(lang.STRINGS.UnsupportedFileType);
        }
        if ((url && url.length + files.length <= 4) || (!url && files.length <= 4)) {
            let formData = new FormData();
            formData.append('task_id', task_id);
            formData.append('ques_id', event.target.getAttribute('data-id'));
            formData.append('reference_flag', 1);
            Array.from(files).forEach((file) => {
                formData.append('file', file);
            });
            this.uploadImage(name, formData);
        } else {
            AlertModal.showAlert(lang.STRINGS.MaxImages);
        }
    };

    /**
     * @description This function open or closes the preview modal.
     */
    showHidePreviewModel = () => {
        let { showPreviewModel } = this.state;
        this.setState({ showPreviewModel: !showPreviewModel });
    };

    /**
     * @description This function shows image preview.
     * @param quesIndex It consists index of work order whose against the image is uploaded.
     * @param imgObj It consists data of image file
     * @param imgIndex It consists index of image to identify which image is selected out of multiple images of same work order.
     */
    showHideImageModel = (quesIndex, imgObj, imgIndex) => {
        let { showImageModel } = this.state;
        this.setState({
            showImageModel: !showImageModel,
            quesIndex,
            imgObj,
            imgIndex
        });
    };

    /**
     * @description This function deletes the particular selected image of the work order.
     */
    deleteImage = async () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { taskInfo, allQuesData, imgObj, quesIndex, imgIndex } = this.state;
        let { task_id } = taskInfo;
        const data = {
            task_id,
            img_id: imgObj.img_id
        };

        this.showHideImageModel();
        spinnerService.show('Oval', lang.STRINGS.DELETINGIMAGE);
        try {
            const config = {
                headers: { authorization: `Bearer ${getData('token')}`, language: language }
            };
            let response = await Axios.post(CONFIG.deleteTaskImages, data, config);
            spinnerService.hide('Oval');
            if (response && response.data && response.data.responseCode == 200) {
                allQuesData[quesIndex]['url'].splice(imgIndex, 1);
                this.setState({ allQuesData });
            }
            if (response && response.data && response.data.responseCode == 401) {
                logoutUnauthorizedUser(this, response.data.message);
            } else {
                AlertModal.showAlert(response.data.message);
            }
        } catch (error) {
            spinnerService.hide('Oval');
            AlertModal.showAlert(error.message);
        }
    };

    /**
     * @description This function sets the value of descrepancy drop-down according to its WO type.
     * @param data It has data value of descrepancies of all work order types
     */
    getSelectBox = (index, data) => {
        let { woOptionsArr, taskInfo } = this.state;
        let { task_status } = taskInfo;
        let optionArr = woOptionsArr?.[data.long_heading];
        return (
            <select
                className="form-control"
                id={index}
                name="validation"
                value={data.validation ? data.validation : ''}
                onChange={this.handleChange}
                disabled={task_status == 3}>
                <option disabled value="">
                    Select Option
                </option>
                {optionArr?.map((quesObj, indexKey) => (
                    <option key={indexKey} value={quesObj}>
                        {quesObj}
                    </option>
                ))}
            </select>
        );
    };

    /**
     * @description This function updates the current tab value.
     * @param currentTabName It has value of currently selected tab
     */
    tabChange = (currentTabName) => {
        this.setState({ currentTabName, filterVal: '' });
    };

    /**
     * @description This function updates the current tab value.
     * @param event It has selected event value.
     */
    tabChangeName = (event) => {
        this.setState({ currentTabName: event.target.value });
    };

    /**
     * @description This function restrict alphabets and special characters and only allows numbers upto two decimal places.
     */
    restrictToNumber = (event, quesId) => {
        let { value } = event.target;
        let numRejex = /^\d*\.?\d{0,2}$/;
        if (numRejex.test(value)) {
            this.handleChange(event, quesId);
        }
    };

    /**
     * @description This function verifies that all the filled details saved or not and confirms
     * with the reviewer that he/she is sure to assign task back to auditor.
     */
    checkAssignBack = () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { checkDataChange } = this.state;
        if (checkDataChange) {
            AlertModal.showAlert(lang.STRINGS.SaveBeforeAssign);
        } else {
            AlertModal.showAlert(
                lang.STRINGS.ConfirmReassign,
                'assignBack',
                'toggleReasonModal',
                this
            );
        }
    };

    /**
     * @description This function opens modal to enter reason for assigning task back to auditor.
     */
    toggleReasonModal = () => {
        let { openReasonModal } = this.state;
        this.setState({
            openReasonModal: !openReasonModal
        });
    };

    /**
     * @description This function updates the tasks status of the particular task to be
     * In-Progress after task is assigned back to the auditor by reviewer and mails the reason
     * to the auditor.
     */
    assignTaskBack = (reason) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        this.toggleReasonModal();
        let { taskInfo } = this.state;
        let { task_id } = taskInfo;
        const dataObj = {
            task_id: task_id,
            reason
        };
        spinnerService.show('Oval', lang.STRINGS.AssigningBack);
        CallApi('POST', CONFIG.assignBack, {}, dataObj, '', 'assignBack', this, language);
    };

    /**
     * @description assignback API callback function
     * @param response This is the response structure received from API
     */
    assignBack = (response) => {
        AlertModal.showAlert(response.message);
        if (response.success) {
            setTimeout(() => {
                AlertModal.hideAlert();
                this.props.history.goBack();
            }, 2000);
        }
    };

    /**
     * @description This function formats data according to its question
     * @param data It has data objects to be formatted
     */
    formatValue = (data, index) => {
        let val1 = null;
        let val2 = null;
        if (data.long_heading === 'WORK ORDERS - PARTS CONSUMPTION') {
            if (
                data.wo_id === 27 ||
                data.long_ques.trim() == 'Value of parts issued to work order' ||
                data.wo_id === 28 ||
                data.long_ques.trim() == 'Total value of parts transacted (absolute value)'
            ) {
                return '$' + data.value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            } else if (
                data.wo_id === 26 ||
                data.long_ques.trim() == 'Quantity %' ||
                data.wo_id === 30 ||
                data.long_ques.trim() == 'Value %'
            ) {
                let val =
                    !!data.value && data.value != 'NaN' && data.value != 'Null'
                        ? `${data.value} %`
                        : `${0} %`;
                return val;
            } else {
                return data.value ? data.value : 0;
            }
        } else if (data.long_heading === 'GENERAL OBSERVATIONS') {
            if (data.wo_id === 34 || data.long_ques.trim() == 'Value %') {
                this.state.QuesList.map((item) => {
                    if (
                        item.wo_id === 31 ||
                        item.long_ques.trim() ==
                            'Total mechanic hours worked in proposed time period'
                    ) {
                        val1 = item.value;
                    } else if (
                        item.wo_id === 32 ||
                        item.long_ques.trim() == 'Total mechanic hours charged to a work order'
                    ) {
                        val2 = item.value;
                    }
                });
                for (let key of this.state.QuesList) {
                    if (
                        key.long_heading === 'GENERAL OBSERVATIONS' &&
                        (key.wo_id === 34 || key.long_ques.trim() == 'Value %')
                    ) {
                        const dataVal = (parseFloat(val2) / parseFloat(val1)) * 100 || 0;
                        key.value = dataVal === Infinity || !dataVal ? 0 : dataVal.toFixed(2);
                        return `${key.value} %`;
                    }
                }
            } else {
                return data.value ? data.value : 0;
            }
        } else {
            return data.value ? data.value : 0;
        }
    };

    /**
     * @description This function downloads the excel of the work order task.
     */
    downloadExcel = () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { taskInfo } = this.state;
        let { task_id } = taskInfo;
        const startDate = new Date(Date.parse(taskInfo.task_start_date));
        const endDate = new Date(Date.parse(taskInfo.task_end_date));
        const finalStartDate =
            (startDate.getMonth() < 10
                ? '0' + (startDate.getMonth() + 1)
                : startDate.getMonth() + 1) +
            (startDate.getDate() < 10 ? '0' + startDate.getDate() : startDate.getDate()) +
            startDate.getFullYear();
        const finalEndDate =
            (endDate.getMonth() < 10 ? '0' + (endDate.getMonth() + 1) : endDate.getMonth() + 1) +
            (endDate.getDate() < 10 ? '0' + endDate.getDate() : endDate.getDate()) +
            endDate.getFullYear();
        const fileName = `${taskInfo.plant_code}-WO-${finalStartDate}-${finalEndDate}`;
        downloadFile(
            `${CONFIG.downloadWOExcel
                .replace('{task_id}', task_id)
                .replace('{fileName}', fileName)}`,
            `${fileName}.xlsx`,
            lang.STRINGS.DOWNLOADINGEXCEL,
            true,
            language
        );
    };

    /**
     * @description This function handles the task's confirmation for declaration.
     */
    handleCheckBox = () => {
        let { confirmation } = this.state;
        this.setState({
            confirmation: !confirmation
        });
    };

    /**
     * @description Sets the default validation value for each question in case there is no value present.
     * @param data It consist of values regarding particular question.
     */
    setValidation = (data) => {
        if (
            data.long_heading.trim() == 'WORK ORDERS - PARTS CONSUMPTION' &&
            (data.wo_id == 26 ||
                data.wo_id == 30 ||
                data.long_ques.trim() == 'Quantity %' ||
                data.long_ques.trim() == 'Value %')
        ) {
            return (data.validation =
                !!data.value && parseFloat(data.value) >= 95 ? 'Pass' : 'Major NC');
        } else {
            return (data.validation = !data.value || data.value == '0' ? 'Pass' : 'Major NC');
        }
    };

    getClassName = (data, index) => {
        if (
            data.wo_id == 2 ||
            data.wo_id == 3 ||
            data.wo_id == 4 ||
            data.wo_id == 5 ||
            data.wo_id == 6 ||
            data.wo_id == 8 ||
            data.wo_id == 9 ||
            data.wo_id == 10 ||
            data.wo_id == 11 ||
            data.wo_id == 12 ||
            data.wo_id == 13 ||
            data.wo_id == 14 ||
            data.wo_id == 16 ||
            data.wo_id == 17 ||
            data.wo_id == 20 ||
            data.wo_id == 21 ||
            data.wo_id == 22 ||
            data.wo_id == 26 ||
            data.wo_id == 30 ||
            data.wo_id == 34
        ) {
            if (
                (data.validation !== 'Pass' &&
                    !!data.auditor_comments &&
                    !!data.reviewer_comments) ||
                data.validation === 'Pass'
            ) {
                return 'completed-info';
            } else {
                return 'missing-info';
            }
        }
        if (data.wo_id == 19)
            if (
                ((data.validation !== 'Pass' &&
                    !!data.auditor_comments &&
                    !!data.reviewer_comments) ||
                    data.validation == 'Pass') &&
                !!data.value
            ) {
                return 'completed-info';
            } else {
                return 'missing-info';
            }
        if (data.wo_id == 31) {
            if (!!data.value) {
                return 'completed-info';
            } else {
                return 'missing-info';
            }
        }
    };

    render() {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let {
            allQuesData,
            QuesList,
            taskInfo,
            showImageModel,
            imgObj,
            currentTabName,
            heading,
            showPreviewModel,
            quesValidation,
            openReasonModal,
            filterArr,
            filterVal,
            confirmation,
            isSubmitClicked
        } = this.state;
        let { task_status } = taskInfo ? taskInfo : {};
        return (
            <div className="work-order-wrap">
                {/* <IdleTimerComponent onSave={() => this.submitAns(false, true)} /> */}
                <div className="filter-container barcode-scanner">
                    <div className="barcode-excel">
                        <button className="btn-blue" onClick={this.downloadExcel}>
                            {lang.BUTTONS.DOWNLOADEXCEL}
                        </button>
                    </div>
                    {currentTabName == 'WORK ORDERS - REFERENCE' && (
                        <div className="form-group">
                            <label>{lang.LABLES.FILTERWO}</label>&nbsp;
                            <select
                                className="form-control valid"
                                name="filterVal"
                                value={filterVal}
                                title={filterVal}
                                onChange={this.handleFilter}>
                                <option value="">{lang.LABLES.All}</option>
                                {filterArr.map((data, index) => (
                                    <option key={index} value={data}>
                                        {data}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
                <div className="headiong-outer">
                    <h4>{lang.STRINGS.AuditTasksQuestions}</h4>
                </div>
                <div
                    className={
                        currentTabName !== 'WORK ORDERS - REFERENCE' ? 'work-order-tabs-outer' : ''
                    }>
                    <div className="work-order-tabs">
                        {heading.map((data, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`btn-wrk-ordr  tablinks
              ${currentTabName == data ? 'active' : ''}`}
                                onClick={() => this.tabChange(data)}>
                                {data !== 'WORK ORDERS - PM'
                                    ? data.toLowerCase()
                                    : 'Work Orders - PM'}
                            </button>
                        ))}
                    </div>
                    <select className="tab-select" onClick={(e) => this.tabChangeName(e)}>
                        {heading.map((data, index) => (
                            <option key={index} value={data}>
                                {data !== 'WORK ORDERS - PM'
                                    ? data.toLowerCase()
                                    : 'Work Orders - PM'}
                            </option>
                        ))}
                    </select>
                    <div className="table-outer tabcontent active">
                        <div
                            className={
                                currentTabName == 'WORK ORDERS - REFERENCE'
                                    ? 'form-row task-row'
                                    : ''
                            }>
                            {currentTabName == 'WORK ORDERS - REFERENCE' ? (
                                allQuesData.map((data, index) => {
                                    if (!filterVal || filterVal === data.long_heading) {
                                        return (
                                            <div className="column-four" key={index} id={index}>
                                                {/* <div className={`summary-boxes ${(data.manually_tab == 1) ? 'completed-info ' : 'completed-info'}`}> */}
                                                <div className="summary-boxes">
                                                    <ul>
                                                        <li>
                                                            {lang.LABLES.AssetNumber} :{' '}
                                                            <strong>{data.asset_no}</strong>
                                                        </li>
                                                        <li>
                                                            {lang.LABLES.OrderNumber} :{' '}
                                                            <strong>{data.wo_order}</strong>
                                                        </li>
                                                        <li>
                                                            {lang.LABLES.WORKORDERTYPE} :{' '}
                                                            <strong>{data.long_heading}</strong>
                                                        </li>
                                                        <li>
                                                            {lang.LABLES.WODESCRIPTION} :{' '}
                                                            <strong>{data.wo_desc}</strong>
                                                        </li>
                                                        <li>
                                                            {lang.LABLES.Status} :{' '}
                                                            <strong>{data.status}</strong>
                                                        </li>
                                                        <li>
                                                            {lang.LABLES.SCHEDULEDSTARTDATE} :{' '}
                                                            <strong>{data.start_date}</strong>
                                                        </li>
                                                    </ul>
                                                    <div className="form-group">
                                                        <span>{lang.LABLES.Discrepancy}</span>
                                                        {this.getSelectBox(index, data)}
                                                    </div>
                                                    <div className="data-upload-col">
                                                        <div className="upload-file-control">
                                                            <span>{lang.LABLES.UploadImage}</span>
                                                            <input
                                                                type="file"
                                                                id={data.order_id}
                                                                name={index}
                                                                onChange={(event) =>
                                                                    this.handleFile(event, data)
                                                                }
                                                                disabled={task_status == 3}
                                                            />
                                                            <label
                                                                htmlFor={data.order_id}
                                                                onChange={(event) =>
                                                                    this.handleFile(event, data)
                                                                }>
                                                                {lang.LABLES.UploadImage}
                                                            </label>
                                                        </div>
                                                        <div className="group-upload-images">
                                                            {data.url &&
                                                                data.url.map((imgObj, imgIndex) => (
                                                                    <img
                                                                        key={imgIndex}
                                                                        src={imgObj.img_path}
                                                                        alt="Images Icons"
                                                                        className="upload-images"
                                                                        onClick={() => {
                                                                            if (task_status != 3) {
                                                                                this.showHideImageModel(
                                                                                    index,
                                                                                    imgObj,
                                                                                    imgIndex
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                ))}
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <span>{lang.LABLES.COMMENT}</span>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id={index}
                                                            name="comments"
                                                            value={
                                                                data.comments ? data.comments : ''
                                                            }
                                                            onChange={this.handleChangeWithLength}
                                                            disabled={task_status == 3}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <span>{lang.LABLES.Recommendation}</span>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id={index}
                                                            name="reviewer_comments"
                                                            value={
                                                                data.reviewer_comments
                                                                    ? data.reviewer_comments
                                                                    : ''
                                                            }
                                                            onChange={this.handleChangeWithLength}
                                                            disabled={task_status == 3}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                            ) : (
                                <div className="table-responsive">
                                    <table className="gen-audt-mch-sts">
                                        <tbody>
                                            <tr>
                                                <th width="40%">{lang.LABLES.Observation}</th>
                                                <th width="15%">{lang.LABLES.Value}</th>
                                                {currentTabName !== 'GENERAL OBSERVATIONS' && (
                                                    <th width="15%">{lang.LABLES.Validation}</th>
                                                )}
                                                {/* : ''} */}
                                                <th
                                                    width={
                                                        currentTabName !== 'GENERAL OBSERVATIONS'
                                                            ? '15%'
                                                            : '22.5%'
                                                    }>
                                                    {lang.LABLES.Impact}
                                                </th>
                                                <th
                                                    width={
                                                        currentTabName !== 'GENERAL OBSERVATIONS'
                                                            ? '15%'
                                                            : '22.5%'
                                                    }>
                                                    {lang.LABLES.Recommendation}
                                                </th>
                                            </tr>
                                            {QuesList.filter(
                                                (element) => element.long_heading == currentTabName
                                            ).map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        {/* className={this.getClassName(data, index)} */}
                                                        <td>{data.long_ques}</td>
                                                        <td>
                                                            {currentTabName !=
                                                                'GENERAL OBSERVATIONS' &&
                                                            currentTabName !=
                                                                'WORK ORDERS - WARRANTY' ? (
                                                                !!data.value ? (
                                                                    this.formatValue(data, index)
                                                                ) : (
                                                                    0
                                                                )
                                                            ) : (currentTabName ===
                                                                  'GENERAL OBSERVATIONS' &&
                                                                  (data.wo_id === 31 ||
                                                                      data.long_ques.trim() ==
                                                                          'Total mechanic hours worked in proposed time period')) ||
                                                              (currentTabName ===
                                                                  'WORK ORDERS - WARRANTY' &&
                                                                  (data.wo_id === 19 ||
                                                                      data.long_ques.trim() ==
                                                                          'Number of Claims')) ? (
                                                                <input
                                                                    className={`form-control ${
                                                                        task_status == 2 &&
                                                                        !!isSubmitClicked &&
                                                                        !data.value
                                                                            ? 'blank-field'
                                                                            : ''
                                                                    }`}
                                                                    type="text"
                                                                    id={index}
                                                                    name="value"
                                                                    value={
                                                                        data.value ? data.value : ''
                                                                    }
                                                                    onChange={(e) =>
                                                                        this.restrictToNumber(
                                                                            e,
                                                                            data.ques_id
                                                                        )
                                                                    }
                                                                    disabled={task_status == 3}
                                                                />
                                                            ) : (
                                                                this.formatValue(data, index)
                                                            )}
                                                        </td>
                                                        {data.is_display === 0 &&
                                                        currentTabName !==
                                                            'GENERAL OBSERVATIONS' ? (
                                                            <td>
                                                                <select
                                                                    className="form-control"
                                                                    id={index}
                                                                    name="validations"
                                                                    value={
                                                                        data.validation
                                                                            ? data.validation
                                                                            : this.setValidation(
                                                                                  data
                                                                              )
                                                                    }
                                                                    onChange={(e) =>
                                                                        this.handleChange(
                                                                            e,
                                                                            data.ques_id
                                                                        )
                                                                    }
                                                                    disabled={task_status == 3}>
                                                                    <option
                                                                        disabled
                                                                        hidden
                                                                        value="">
                                                                        {lang.LABLES.SelectOption}
                                                                    </option>
                                                                    {quesValidation.map(
                                                                        (data, validIndex) => {
                                                                            return (
                                                                                <option
                                                                                    key={validIndex}
                                                                                    value={data}>
                                                                                    {data}
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </select>
                                                            </td>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {!(
                                                            data.is_display && data.is_display == 1
                                                        ) && (
                                                            <td>
                                                                <div className="form-group">
                                                                    <input
                                                                        className={`form-control ${
                                                                            task_status == 2 &&
                                                                            !!isSubmitClicked &&
                                                                            data.validation !==
                                                                                'Pass' &&
                                                                            !data.auditor_comments
                                                                                ? 'blank-field'
                                                                                : ''
                                                                        }`}
                                                                        type="text"
                                                                        id={index}
                                                                        name="audit_comments"
                                                                        value={
                                                                            data.auditor_comments
                                                                                ? data.auditor_comments
                                                                                : ''
                                                                        }
                                                                        disabled={task_status == 3}
                                                                        onChange={(e) =>
                                                                            this.handleChangeWithLength(
                                                                                e,
                                                                                data.ques_id
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </td>
                                                        )}
                                                        {!(
                                                            data.is_display && data.is_display == 1
                                                        ) && (
                                                            <td>
                                                                <div className="form-group">
                                                                    <input
                                                                        className={`form-control ${
                                                                            task_status == 2 &&
                                                                            !!isSubmitClicked &&
                                                                            data.validation !==
                                                                                'Pass' &&
                                                                            !data.reviewer_comments
                                                                                ? 'blank-field'
                                                                                : ''
                                                                        }`}
                                                                        type="text"
                                                                        id={index}
                                                                        name="review_comments"
                                                                        value={
                                                                            data.reviewer_comments
                                                                                ? data.reviewer_comments
                                                                                : ''
                                                                        }
                                                                        disabled={task_status == 3}
                                                                        onChange={(e) =>
                                                                            this.handleChangeWithLength(
                                                                                e,
                                                                                data.ques_id
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </td>
                                                        )}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <input
                        type="checkbox"
                        name="confirmation"
                        value={confirmation}
                        onChange={this.handleCheckBox}
                    />
                    <label htmlFor="confirmation"> {lang.STRINGS.CONFIRMATIONMESSAGE}</label>
                </div>
                {allQuesData.length > 0 && task_status != 3 && (
                    <div className="submition-outer">
                        <button className="btn-prmry" onClick={this.checkAssignBack}>
                            {lang.BUTTONS.AssignBack}
                        </button>
                        <button
                            className="btn-prmry"
                            onClick={() => {
                                this.submitAns(false);
                            }}>
                            {lang.BUTTONS.SavePreview}
                        </button>
                        <button className="btn-prmry" onClick={() => this.submitAns(true)}>
                            {lang.BUTTONS.Submit}
                        </button>
                    </div>
                )}
                {showImageModel && (
                    <ImagePrevew
                        isModalOpen={showImageModel}
                        imgObj={imgObj}
                        showDeleteButton={true}
                        onCancel={this.showHideImageModel}
                        deleteImage={this.deleteImage}
                        langData={this.props?.langData}
                    />
                )}
                {showPreviewModel && (
                    <PreviewModal
                        isModalOpen={showPreviewModel}
                        data={allQuesData}
                        taskInfo={taskInfo}
                        quesData={QuesList}
                        onClose={this.showHidePreviewModel}
                        langData={this.props?.langData}
                    />
                )}
                {openReasonModal && (
                    <ReasonModal
                        isModalOpen={openReasonModal}
                        assignBack={this.assignTaskBack}
                        onCancel={this.toggleReasonModal}
                        langData={this.props?.langData}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        allQuesData: state.common.get('woQuesData')
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getWoData: (url, data, type, loaderMsg, obj, language = 'en') =>
            dispatch(getPostDataWithType(url, data, type, loaderMsg, obj, language)),
        uploadImage: (url, data, loaderMsg, obj, language = 'en') =>
            dispatch(getPostDataWithAction(url, data, null, null, loaderMsg, obj, language)),
        submitAns: (url, data, path, loaderMsg, obj, language = 'en') =>
            dispatch(getPostDataWithAction(url, data, path, null, loaderMsg, obj, language))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WOReviewerAnsComp);
