/**
 * @author Rehan Rizvi
 * @file PRAuditorAns Component
 * @flow
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { PR_DATA } from '../../../../redux/actions/common_action_types';
import {
    getPostDataWithType,
    getPostDataWithAction
} from '../../../../redux/actions/common_action';
import CONFIG from '../../../../configuration';
import AlertModal from '../../../../shared/modals/AlertModal';
import { spinnerService } from '../../../../shared/services/spinner.service';
import { STRINGS } from '../../../../shared/constants/data';
import ScannerModule from '../../../../shared/BarcodeScanner/ScannerModule';
import { serverCalls } from '../../../../shared/services/serverCalls.service';
import ImagePrevew from '../../../../shared/modals/ImagePrevew';
import DataCardModal from '../../../../shared/modals/DataCardModal';
import PreviewModal from '../../../../shared/modals/PRPreviewModal';
import IdleTimerComponent from '../../../Common/IdleTimeCheck/IdleTimerComponent';
import { getRoleBasedRoute } from '../../../../shared/utils/helper.util';
import { checkFileType, checkVideoType } from '../../../../shared/utils/helper.util';
import { getData } from '../../../../shared/utils/storage.util';
import { logoutUnauthorizedUser } from '../../../../shared/utils/auth.util';
import QuantifiableModal from '../../../../shared/modals/QuantifiableModal';
import Thumbnail from '../../../Common/Thumbnail/Thumbnail';
import ImageVideoSelectionModal from '../../../../shared/modals/ImageVideoSelectionModal';
import LANGUAGECONST from '../../../../shared/constants/language';
import { languageData } from '../../../../redux/actions/languageAction';

class PRAuditorAnsComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            heading: ['ORACLE SYSTEM AUDIT', 'PARTS ROOM SAFETY & SECURITY'],
            isQuantifiableModalOpen: false,
            currentTabName: 'ORACLE SYSTEM AUDIT',
            questionData: {},
            selectedType: null,
            index: null,
            searchVal: '',
            searchedItem: [],
            showImageModel: false,
            showPreviewModel: false,
            isUploadFile: false,
            quesIndex: null,
            imgObj: null,
            imgIndex: null,
            addedItem: null,
            savedItems: null,
            uploadObj: null,
            taskInfo: {},
            QuesList: [],
            submitObj: {},
            confirmation: false,
            checkSave: true,
            scan: false,
            checkDataChange: false,
            onBarcodeOpen: false,
            showMannualModel: false,
            options: [
                { label: 'Major NC', value: 'Major NC' },
                { label: 'Minor NC', value: 'Minor NC' },
                { label: 'Pass', value: 'Pass' }
            ],
            isSubmitClicked: false
        };
    }

    componentWillMount = () => {
        let { taskInfo } = this.props;
        this.setState({ taskInfo });
    };

    componentDidMount = () => {
        this.interval = setInterval(() => {
            this.submitAns(false, true);
        }, 15000);
    };

    componentWillReceiveProps = (nextProps) => {
        let { QuesList, prData, countObj } = nextProps;
        let { searchVal, searchedItem } = this.state;
        if (prData.length == 0 && searchVal) {
            this.showHideMannualCardModal(searchVal);
        }
        if (prData && prData.length > 0 && !searchedItem.includes(prData[0].item_number)) {
            QuesList.unshift(prData[0]);
        }
        if (QuesList && QuesList.length > 0) {
            QuesList.map((data) => {
                if (data.long_heading === 'ORACLE SYSTEM AUDIT') {
                    if (!searchedItem.length || !this.checkItem(data.item_number)) {
                        searchedItem.push(data.item_number);
                    }
                }
            });
        }
        this.setState({ QuesList: QuesList || [], addedItem: searchedItem.length });
        if (this.state.checkSave) {
            this.setState({
                savedItems: !!countObj
                    ? !!countObj.items_audited
                        ? countObj.items_audited
                        : 0
                    : 0,
                checkSave: false
            });
        }
    };

    /**
     * @description This function checks if item number is present in list or not.
     * @param item It has value to be checked in list.
     */
    checkItem = (item) => {
        let { searchedItem } = this.state;
        for (let data of searchedItem) {
            if (data === item) {
                return true;
            }
        }
    };

    /**
     * @description This function updates the current tab value.
     * @param currentTabName It has value of currently selected tab
     */
    tabChange = (currentTabName) => {
        this.setState({ currentTabName });
    };

    /**
     * @description This function updates the current tab value.
     * @param event It has selected event value.
     */
    tabChangeName = (event) => {
        this.setState({ currentTabName: event.target.value });
    };

    /**
     * @description This function handles open and close of modal to enter work order manually.
     */
    showHideMannualCardModal = (searchVal) => {
        const { showMannualModel } = this.state;
        this.setState({
            searchVal,
            showMannualModel: !showMannualModel
        });
    };

    /**
     * @description This function add data of manually added item card in database
     * @param data It is a data object of currently added item.
     */
    addCard = (data) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { taskInfo } = this.state;
        this.showHideMannualCardModal('');
        let obj = { ...data, asset_type_id: 2, task_id: taskInfo.task_id };
        this.props.getData(
            CONFIG.insertTabs,
            obj,
            PR_DATA,
            lang.STRINGS.ADDINGCARD,
            this,
            language
        );
    };

    /**
     * @description This function search the item number and fetch its data.
     */
    searchPRoom = async () => {
        let { searchVal, taskInfo, searchedItem } = this.state;
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { task_id } = taskInfo;
        if (!!searchVal) {
            if (searchedItem.includes(searchVal)) {
                //ITEMINLISTALREADY
                return AlertModal.showAlert(lang.STRINGS.ITEMINLISTALREADY);
            }
            let obj = { item_number: searchVal, task_id };
            await this.props.getData(
                CONFIG.searchPRData,
                obj,
                PR_DATA,
                lang.STRINGS.SEARCHNGPR,
                this,
                language
            );
        } else {
            return AlertModal.showAlert(lang.STRINGS.ITEMNUMBERSEARCH);
        }
    };

    /**
     * @description This function set value of searched item number.
     */
    handleSearch = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
    };

    /**
     * @description This function set values of item cards to data object.
     */
    handleChange = (event) => {
        let { QuesList } = this.state;
        let { id, name, value } = event.target;
        QuesList[id][name] = value;
        this.setState({ QuesList, checkDataChange: true });
    };

    /**
     * @description This function set character limit on fields
     */
    handleChangeWithLength = (event) => {
        let { value } = event.target;
        if (value.length <= 400) {
            this.handleChange(event);
        }
    };

    /**
     * @description This function handles value of parameter that has radio button
     */
    handleRadio = (id, name, value) => {
        let { QuesList } = this.state;
        QuesList[id][name] = value;
        this.setState({ QuesList, checkDataChange: true });
    };

    /**
     * @description This function restrict alphabets and special characters and only allows numbers upto two decimal places.
     */
    restrictToNumber = (event) => {
        let { value } = event.target;
        let numRejex = /^\d*\.?\d{0,2}$/;
        if (numRejex.test(value)) {
            this.handleChange(event);
        }
    };

    /**
     * @description This function validates the values of data object on submit for safty and security tab
     * @param dataArr It has data objects to be validated
     */
    validateSaftyAndSecurity = (dataArr) => {
        let error = null;
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { checkDataChange } = this.state;
        // if (!checkDataChange) {
        for (let data of dataArr) {
            // if (!data.isInspected && !data.isApplicable) {
            if (!data.url || data.url.length == 0) {
                return (error = `${lang.STRINGS.IMAGEREQ} “${data.long_ques}”`);
            }
            if (!!data.isApplicable) {
                if (!data.observation)
                    return (error = `${lang.STRINGS.OBSERVATIONREQ} “${data.long_ques}”`);
            }
            // }
        }
        // } else {
        //   return error = 'Please save before submit.';
        // }
        return error;
    };

    /**
     * @description This function validates the values of data object on submit for item cards
     * @param dataArr It has data objects to be validated
     */
    validateData = (dataArr) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        this.setState({
            isSubmitClicked: true
        });
        let error = null;
        let { checkDataChange } = this.state;
        // if (!checkDataChange) {
        for (let data of dataArr) {
            let {
                item_number_correct,
                item_locator_correct,
                item_description_correct,
                bin_labeled_correct,
                actual_quantity
            } = data;
            let obj = {
                item_number_correct,
                item_locator_correct,
                item_description_correct,
                bin_labeled_correct,
                actual_quantity
            };
            for (let key in obj) {
                if (!obj[key]) {
                    let reqVar =
                        key == 'item_number_correct'
                            ? 'Item Number Correct'
                            : key == 'item_locator_correct'
                            ? 'Item Locator Correct'
                            : key == 'item_description_correct'
                            ? 'Item Description Correct'
                            : key == 'bin_labeled_correct'
                            ? 'Bin Labeled Correct'
                            : key == 'actual_quantity'
                            ? 'Actual Quantity'
                            : '';
                    return (error = `${reqVar} ${lang.STRINGS.MANDATORYFIELDPR} ${data.item_number}.`);
                }
            }
        }
        // } else {
        //   return error = 'Please save before submit.';
        // }
        return error;
    };

    /**
     * @description This function formats data.
     * @param dataArr It has data to be formatted
     * @param filterKey It has value of key according to which the data is to be formatted
     */
    formatData = (dataArr, filterKey) => {
        let data = dataArr.filter((obj) => {
            if (obj.long_heading === filterKey) {
                return obj;
            }
        });
        return data;
    };

    /**
     * @description This function formats data to select only the required keys for data object
     * @param dataArr It has data to be formatted
     */
    formatDataKey = (dataArr) => {
        let data = dataArr.map((obj) => {
            let {
                ques_id,
                item_number,
                item_number_correct,
                item_locator,
                item_locator_correct,
                actual_quantity,
                item_description,
                item_description_correct,
                oracle_quantity,
                bin_labeled_correct,
                lot_number,
                manually_tab
            } = obj;
            let objKeys = {
                ques_id,
                item_number,
                item_locator,
                item_description,
                lot_number,
                oracle_quantity,
                item_number_correct: item_number_correct ? item_number_correct : null,
                item_locator_correct: item_locator_correct ? item_locator_correct : null,
                actual_quantity: actual_quantity ? actual_quantity : null,
                item_description_correct: item_description_correct
                    ? item_description_correct
                    : null,
                bin_labeled_correct: bin_labeled_correct ? bin_labeled_correct : null,
                manually_tab: manually_tab ? manually_tab : 0
            };
            return objKeys;
        });
        return data;
    };

    /**
     * @description This function calls on save and submit data. On save it show preview of
     * data and on submit that task is being assigned to the reviewer. It also get called after being
     * idle for 5 minutes and saves the data only without preview.
     * @param isSubmit It has boolean value to check if the function is called to save or submit the data
     * @param isIdle It has boolean value to check if function is call due to idle situation for 5 min or not
     */
    submitAns = async (isSubmit, isIdle = false) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { taskInfo, QuesList, confirmation } = this.state;
        let { task_id, task_status, reviewer_flag } = taskInfo;
        let ques = this.formatData(QuesList, 'ORACLE SYSTEM AUDIT');
        let saftyQues = this.formatData(QuesList, 'PARTS ROOM SAFETY & SECURITY');
        // let error = isSubmit ? this.validateData(ques) : this.setState({ checkDataChange: false });
        // let saftyError = isSubmit ? this.validateSaftyAndSecurity(saftyQues) : this.setState({ checkDataChange: false });
        // if (error || saftyError) {
        //   this.setState({ currentTabName: error ? "ORACLE SYSTEM AUDIT" : "PARTS ROOM SAFETY & SECURITY" });
        //   return AlertModal.showAlert(error ? error : saftyError);
        // }
        let data = {
            task_id,
            ques: this.formatDataKey(ques),
            status: isSubmit ? task_status + 1 : task_status,
            isAuditor: true
        };
        let addTaskData = {
            task_id,
            task_status: isSubmit ? task_status + 1 : task_status,
            asset_type_id: 2,
            reviewer_flag: reviewer_flag ? reviewer_flag : 0,
            ar_reviewer_flag: 0,
            ques: saftyQues,
            isEmail: isSubmit ? true : false,
            isTaskCompleted: isSubmit ? 1 : 0
        };
        if (!isSubmit) {
            this.setState({
                checkSave: true
            });
            let route = isSubmit ? getRoleBasedRoute() : 'SAVE';
            let orace = await this.props.submitAns(
                CONFIG.addPInventryQues,
                data,
                !isIdle ? route : 'idle',
                null,
                lang.STRINGS.SubmittingData,
                this,
                language
            );
            if (orace)
                await this.props.submitAns(
                    CONFIG.addTaskQues,
                    addTaskData,
                    !isIdle ? route : 'idle',
                    null,
                    lang.STRINGS.SubmittingData,
                    this,
                    language
                );
            if (!isIdle) {
                this.showHidePreviewModel();
            }
        } else {
            this.setState({ submitObj: data, addTaskData }, () => {
                if (!confirmation) {
                    AlertModal.showAlert(lang.STRINGS.AcceptDeclaration);
                } else {
                    this.onSubmit();
                }
            });
        }
    };

    /**
     * @description This function saves data of item card data
     */
    onSubmit = async () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        const { submitObj } = this.state;
        let route = getRoleBasedRoute();
        this.props.submitAns(
            CONFIG.addPInventryQues,
            submitObj,
            'checkAddTask',
            this.checkAddTask,
            lang.STRINGS.SubmittingData,
            this,
            language
        );
    };

    /**
     * @description This function opens modal to enter suggestion from auditor
     */
    checkAddTask = () => {
        AlertModal.showAlert('', 'submitAudit', 'submitTask', this);
    };

    /**
     * @description This function submits data with suggestion message and
     * the task is being assigned to the reviewer.
     * @param suggestion_msg It has value of sugestion message from auditor, it can be null.
     */
    submitTask = (suggestion_msg) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        const { addTaskData } = this.state;
        addTaskData.suggestion_msg = suggestion_msg;
        let route = getRoleBasedRoute();
        this.props.submitAns(
            CONFIG.addTaskQues,
            addTaskData,
            route,
            null,
            lang.STRINGS.SubmittingData,
            this,
            language
        );
    };

    /**
     * @description This function uploads the image.
     * @param data It consists data of image file.
     */
    uploadImage = async (index, data) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { QuesList } = this.state;
        spinnerService.show('Oval', lang.STRINGS.UploadingImage);
        try {
            const config = {
                headers: { authorization: `Bearer ${getData('token')}`, language: language }
            };
            let response = await Axios.post(CONFIG.uploadQuesImage, data, config);
            spinnerService.hide('Oval');
            if (response && response.data && response.data.responseCode == 200) {
                QuesList[index]['url'] = QuesList[index]['url']
                    ? QuesList[index]['url'].concat(response.data.response?.url)
                    : response.data.response?.url;
                this.setState({ QuesList });
            }
            if (response && response.data && response.data.responseCode == 401) {
                logoutUnauthorizedUser(this, response.data.message);
            } else {
                AlertModal.showAlert(response.data.message);
            }
        } catch (error) {
            spinnerService.hide('Oval');
            AlertModal.showAlert(error.message);
        }
    };

    /**
     * @description This function handle images data.
     * @param data It consists data of image file.
     */
    handleFile = (event, data, type) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        this.handleUploadModalClose();
        let { id, name, files } = event.target;
        if (
            files.length &&
            ((type === 'image' && !checkFileType(files[0].type)) ||
                (type === 'video' && !checkVideoType(files[0].type)))
        ) {
            return AlertModal.showAlert(lang.STRINGS.UnsupportedFileType);
        }
        let { taskInfo, currentTabName } = this.state;
        let { url } = data;
        let { task_id } = taskInfo;
        let inventoryFlag = currentTabName == 'ORACLE SYSTEM AUDIT' ? 1 : 0;
        let referenceFlag = 0;
        if ((url && url.length + files.length <= 4) || (!url && files.length <= 4)) {
            let formData = new FormData();
            formData.append('task_id', task_id);
            formData.append('ques_id', event.target.getAttribute('data-id'));
            formData.append('inventory_flag', inventoryFlag);
            formData.append('reference_flag', referenceFlag);
            this.setState({
                checkDataChange: true
            });
            Array.from(files).forEach((file) => {
                formData.append('file', file);
            });
            this.uploadImage(name, formData);
        } else {
            AlertModal.showAlert(lang.STRINGS.MaxImages);
        }
    };

    openUploadModal = (ev, data, index) => {
        ev.preventDefault();
        this.setState({
            isUploadFile: true,
            uploadObj: { data: data, index: index }
        });
    };

    handleUploadModalClose = () => {
        this.setState({
            isUploadFile: false
        });
    };

    /**
     * @description This function set value of the scanned barcode in serachVal variable and calls searchPRoom funciton
     * @param result It has scanned result of barcode
     */
    startScanner = (result) => {
        this.setState({
            searchVal: result?.codeResult?.code
        });
        this.onBarcodeCancel();
        this.searchPRoom();
    };

    /**
     * @description This function opens the barcode scanner.
     */
    openScanner = (scan) => {
        this.setState({
            scan: scan,
            onBarcodeOpen: true
        });
    };

    /**
     * @description This function closes the barcode scanner.
     */
    onBarcodeCancel = () => {
        this.setState({
            onBarcodeOpen: false
        });
    };

    /**
     * @description This function open or closes the preview modal.
     */
    showHidePreviewModel = () => {
        let { showPreviewModel } = this.state;
        this.setState({ showPreviewModel: !showPreviewModel });
    };

    /**
     * @description This function shows image preview.
     * @param quesIndex It consists index of item card or question whose against the image is uploaded.
     * @param imgObj It consists data of image file
     * @param imgIndex It consists index of image to identify which image is selected out of multiple images of same item card or question.
     */
    showHideImageModel = (quesIndex, imgObj, imgIndex) => {
        let { showImageModel } = this.state;
        this.setState({
            showImageModel: !showImageModel,
            quesIndex,
            imgObj,
            imgIndex
        });
    };

    /**
     * @description This function deletes the particular selected image of the item card or question.
     */
    deleteImage = async () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { taskInfo, QuesList, imgObj, quesIndex, imgIndex } = this.state;
        let { task_id } = taskInfo;
        const data = {
            task_id,
            img_id: imgObj.img_id
        };

        this.showHideImageModel();
        let response = await serverCalls(
            CONFIG.deleteTaskImages,
            data,
            lang.STRINGS.DELETINGIMAGE,
            this,
            language
        );
        if (response) {
            QuesList[quesIndex]['url'].splice(imgIndex, 1);
            this.setState({ QuesList, checkDataChange: true });
        }
    };

    /**
     * @description This function deletes the item card.
     * @param data It has the data of the item card to be deleted
     */
    deleteCard = async (index, data) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { QuesList, searchedItem, addedItem, taskInfo } = this.state;
        let { task_id } = taskInfo;
        let { ques_id, is_save, item_number } = data;
        let obj = {
            asset_type_id: 2,
            ques_id,
            task_id,
            is_save: is_save ? is_save : 0
        };
        let response = await serverCalls(
            CONFIG.deleteTabTask,
            obj,
            lang.STRINGS.DELETINGCARD,
            this,
            language
        );
        if (response) {
            if (searchedItem.indexOf(item_number) > -1) {
                searchedItem.splice(searchedItem.indexOf(item_number), 1);
                QuesList.splice(index, 1);
                this.setState({
                    searchedItem,
                    QuesList,
                    addedItem: addedItem - 1,
                    savedItems: response.savedItems
                });
            }
        }
    };

    /**
     * @description This function handles the task's confirmation for declaration.
     */
    handleCheckBox = () => {
        let { confirmation } = this.state;
        this.setState({
            confirmation: !confirmation
        });
    };

    /**
     * @description This function shows drop-down.
     * @param data It consists data of the particular quantifiable question.
     */
    getSelectBox = (index, data, typeId) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { taskInfo } = this.state;
        let { task_status } = taskInfo ? taskInfo : {};
        let { userData } = getData('loginData');
        let isDisabled =
            (userData.role_name === 'auditor' && task_status == 2) || task_status == 3
                ? true
                : false;
        return (
            <React.Fragment>
                <div className="data-upload-col">
                    <a
                        href="!#"
                        onClick={(evt) => {
                            // if (task_status != 3) {
                            this.openQuantifiableModal(evt, data, index, typeId);
                            // }
                        }}
                        className={
                            !data.isApplicable
                                ? data.url.length == 0
                                    ? ''
                                    : ' srv-validation-correct-message'
                                : !!data.isApplicable
                                ? data.url.length != 0 && !!data.observation
                                    ? ' srv-validation-correct-message'
                                    : ' srv-validation-message'
                                : ''
                        }>
                        {lang.STRINGS.ClickToAnswer}
                    </a>
                    <br />
                    <span></span>
                </div>
            </React.Fragment>
            // <select className="form-control" id={index} name="validation"
            //   value={data.validation ? data.validation : ""} onChange={this.handleChange} disabled={isDisabled}>
            //   <option disabled value="">Select Option</option>
            //   {PMQualityOptions.map((data, indexKey) => (
            //     <option key={indexKey} value={data.value}>{data.label}</option>
            //   ))}
            // </select>
        );
    };

    openQuantifiableModal = (evt, selectedQues, index, selectedType) => {
        evt.preventDefault();
        this.setState({
            isQuantifiableModalOpen: true,
            questionData: selectedQues,
            index: index,
            selectedType
        });
    };

    closeQuantifiableModal = () => {
        this.setState({ isQuantifiableModalOpen: false });
    };

    isInspected = (index, value) => {
        let { QuesList } = this.state;
        QuesList[index]['isInspected'] = value;
        this.setState({ QuesList, checkDataChange: true });
    };

    isApplicable = (index, value) => {
        let { QuesList } = this.state;
        QuesList[index]['isApplicable'] = value;
        this.setState({ QuesList, checkDataChange: true });
    };

    componentWillUnmount = () => {
        this.props.removeWOData(PR_DATA);
        clearInterval(this.interval);
    };

    render() {
        let {
            heading,
            currentTabName,
            taskInfo,
            searchVal,
            scan,
            onBarcodeOpen,
            QuesList,
            imgObj,
            uploadObj,
            showPreviewModel,
            showImageModel,
            showMannualModel,
            confirmation,
            addedItem,
            savedItems,
            isQuantifiableModalOpen,
            questionData,
            index,
            selectedType,
            isSubmitClicked,
            isUploadFile
        } = this.state;
        let { countObj } = this.props;
        let { task_status, isAssigned } = taskInfo ? taskInfo : {};
        let isDisabled = task_status == 1 ? false : true;
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        return (
            <div className="work-order-wrap">
                {/* <IdleTimerComponent onSave={() => this.submitAns(false, true)} /> */}
                <div className="filter-container">
                    {currentTabName === 'ORACLE SYSTEM AUDIT' && !isDisabled && (
                        <div className="filter-container barcode-scanner">
                            <div className="barcode-excel">
                                <button
                                    type="button"
                                    className="btn-blue"
                                    onClick={() => this.openScanner(true)}>
                                    {lang.LABLES.SCANITEM}
                                </button>
                            </div>
                            <div className="form-group">
                                <label>{lang.LABLES.ITEMNUMBER}</label>&nbsp;
                                <input
                                    className="form-control"
                                    name="searchVal"
                                    value={searchVal}
                                    onChange={this.handleSearch}
                                />
                                <button
                                    type="button"
                                    className="btn-blue"
                                    onClick={this.searchPRoom}>
                                    {lang.BUTTONS.SEARCH}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="headiong-outer">
                    <h4>{lang.STRINGS.AuditTasksQuestions}</h4>
                </div>
                <div
                    className={
                        currentTabName !== 'ORACLE SYSTEM AUDIT' ? 'work-order-tabs-outer' : ''
                    }>
                    <div className="work-order-tabs">
                        {heading.map((data, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`btn-wrk-ordr  tablinks
                ${currentTabName == data ? 'active' : ''}`}
                                onClick={() => this.tabChange(data)}>
                                {data.toLowerCase()}
                            </button>
                        ))}
                    </div>
                    <select className="tab-select" onChange={(e) => this.tabChangeName(e)}>
                        {heading.map((data, index) => (
                            <option key={index} value={data}>
                                {data.toLowerCase()}
                            </option>
                        ))}
                    </select>
                    {!!countObj?.total_item_count && currentTabName === 'ORACLE SYSTEM AUDIT' && (
                        <span className="item-count-box">{`${lang.LABLES.AUDITED}: ${addedItem} | ${
                            lang.LABLES.PENDING
                        }: ${
                            countObj.items_to_audit - addedItem >= 0
                                ? countObj.items_to_audit - addedItem
                                : 0
                        } | ${lang.LABLES.TOTAL}: ${countObj.total_item_count}`}</span>
                    )}
                    {/* | Saved: ${savedItems} */}
                    <div className="main-cntnt-wrap " id="existing-audits-summary-wrap">
                        <div className="container">
                            <ol className="breadcrumb"></ol>
                        </div>
                        <div className="existing-audits-summary-outer">
                            {currentTabName === 'ORACLE SYSTEM AUDIT' && (
                                <div className="container">
                                    <div className="form-row task-row">
                                        {QuesList.length > 0 &&
                                            QuesList.map((data, index) => {
                                                if (data.long_heading === currentTabName) {
                                                    return (
                                                        <div className="column-four" key={index}>
                                                            {/* <div className={`summary-boxes ${(!!data.item_number_correct && !!data.item_locator_correct &&
                              !!data.item_description_correct && !!data.bin_labeled_correct && !!data.actual_quantity) ?
                              // ((data.manually_tab == 1) ? 'maunual-colour-code' : 'completed-info') : 'missing-info'}`}>
                              'completed-info' : 'missing-info'}`}> */}
                                                            <div className="summary-boxes">
                                                                <ul>
                                                                    <li>
                                                                        {lang.LABLES.ITEMNUMBER} :{' '}
                                                                        <strong>
                                                                            {data.item_number
                                                                                ? data.item_number
                                                                                : 'NA'}
                                                                        </strong>
                                                                    </li>
                                                                    <li>
                                                                        {lang.LABLES.LOTNUMBER} :{' '}
                                                                        <strong>
                                                                            {data.lot_number
                                                                                ? data.lot_number
                                                                                : 'NA'}
                                                                        </strong>
                                                                    </li>
                                                                    <li>
                                                                        {lang.LABLES.LOCATOR} :{' '}
                                                                        <strong>
                                                                            {data.item_locator
                                                                                ? data.item_locator
                                                                                : 'NA'}
                                                                        </strong>
                                                                    </li>
                                                                    <li>
                                                                        {
                                                                            lang.LABLES
                                                                                .ITEMDESCRIPTION
                                                                        }{' '}
                                                                        :{' '}
                                                                        <strong>
                                                                            {data.item_description
                                                                                ? data.item_description
                                                                                : 'NA'}
                                                                        </strong>
                                                                    </li>
                                                                </ul>
                                                                <div className="data-upload-col">
                                                                    <div className="upload-file-control">
                                                                        <span>
                                                                            {
                                                                                lang.LABLES
                                                                                    .UploadImage
                                                                            }
                                                                        </span>
                                                                        <input
                                                                            type="file"
                                                                            id={data.ques_id}
                                                                            data-id={data.ques_id}
                                                                            name={index}
                                                                            disabled={isDisabled}
                                                                            onChange={(event) =>
                                                                                this.handleFile(
                                                                                    event,
                                                                                    data,
                                                                                    'image'
                                                                                )
                                                                            }
                                                                        />
                                                                        <label
                                                                            htmlFor={data.ques_id}>
                                                                            {
                                                                                lang.LABLES
                                                                                    .UploadImage
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                    {data.url &&
                                                                        data.url.map(
                                                                            (imgObj, imgIndex) => (
                                                                                <img
                                                                                    key={imgIndex}
                                                                                    src={
                                                                                        imgObj.img_path
                                                                                    }
                                                                                    alt="Images Icons"
                                                                                    className="upload-images"
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            task_status !=
                                                                                            3
                                                                                        ) {
                                                                                            this.showHideImageModel(
                                                                                                index,
                                                                                                imgObj,
                                                                                                imgIndex
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            )
                                                                        )}
                                                                </div>
                                                                <div className="form-group radio-group">
                                                                    <span>
                                                                        {
                                                                            lang.LABLES
                                                                                .ITEMNUMBERCORRECT
                                                                        }{' '}
                                                                        {!!isSubmitClicked &&
                                                                            !data.item_number_correct && (
                                                                                <span className="srv-validation-message">
                                                                                    *
                                                                                </span>
                                                                            )}
                                                                    </span>
                                                                    <div className="data-upload-col">
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            value="Y"
                                                                            style={{
                                                                                marginRight: '3px'
                                                                            }}
                                                                            checked={
                                                                                data.item_number_correct ==
                                                                                'Y'
                                                                            }
                                                                            onChange={() =>
                                                                                this.handleRadio(
                                                                                    index,
                                                                                    'item_number_correct',
                                                                                    'Y'
                                                                                )
                                                                            }
                                                                            disabled={isDisabled}
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                marginRight: '10px'
                                                                            }}>
                                                                            {lang.LABLES.Yes}
                                                                        </span>
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            value="N"
                                                                            style={{
                                                                                marginRight: '3px'
                                                                            }}
                                                                            checked={
                                                                                data.item_number_correct ==
                                                                                'N'
                                                                            }
                                                                            onChange={() =>
                                                                                this.handleRadio(
                                                                                    index,
                                                                                    'item_number_correct',
                                                                                    'N'
                                                                                )
                                                                            }
                                                                            disabled={isDisabled}
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                marginRight: '4px'
                                                                            }}>
                                                                            {lang.LABLES.No}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group radio-group">
                                                                    <span>
                                                                        {
                                                                            lang.LABLES
                                                                                .ITEMLOCATORCORRECT
                                                                        }{' '}
                                                                        {!!isSubmitClicked &&
                                                                            !data.item_locator_correct && (
                                                                                <span className="srv-validation-message">
                                                                                    *
                                                                                </span>
                                                                            )}
                                                                    </span>
                                                                    <div className="data-upload-col">
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            value="Y"
                                                                            style={{
                                                                                marginRight: '3px'
                                                                            }}
                                                                            checked={
                                                                                data.item_locator_correct ===
                                                                                'Y'
                                                                            }
                                                                            onChange={() =>
                                                                                this.handleRadio(
                                                                                    index,
                                                                                    'item_locator_correct',
                                                                                    'Y'
                                                                                )
                                                                            }
                                                                            disabled={isDisabled}
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                marginRight: '10px'
                                                                            }}>
                                                                            {lang.LABLES.Yes}
                                                                        </span>
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            value="N"
                                                                            style={{
                                                                                marginRight: '3px'
                                                                            }}
                                                                            checked={
                                                                                data.item_locator_correct ===
                                                                                'N'
                                                                            }
                                                                            onChange={() =>
                                                                                this.handleRadio(
                                                                                    index,
                                                                                    'item_locator_correct',
                                                                                    'N'
                                                                                )
                                                                            }
                                                                            disabled={isDisabled}
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                marginRight: '4px'
                                                                            }}>
                                                                            {lang.LABLES.No}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group radio-group">
                                                                    <span>
                                                                        {
                                                                            lang.LABLES
                                                                                .ITEMDESCRIPTIONCORRECT
                                                                        }{' '}
                                                                        {!!isSubmitClicked &&
                                                                            !data.item_description_correct && (
                                                                                <span className="srv-validation-message">
                                                                                    *
                                                                                </span>
                                                                            )}
                                                                    </span>
                                                                    <div className="data-upload-col">
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            value="Y"
                                                                            style={{
                                                                                marginRight: '3px'
                                                                            }}
                                                                            checked={
                                                                                data.item_description_correct ==
                                                                                'Y'
                                                                            }
                                                                            onChange={() =>
                                                                                this.handleRadio(
                                                                                    index,
                                                                                    'item_description_correct',
                                                                                    'Y'
                                                                                )
                                                                            }
                                                                            disabled={isDisabled}
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                marginRight: '10px'
                                                                            }}>
                                                                            {lang.LABLES.Yes}
                                                                        </span>
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            value="N"
                                                                            style={{
                                                                                marginRight: '3px'
                                                                            }}
                                                                            checked={
                                                                                data.item_description_correct ==
                                                                                'N'
                                                                            }
                                                                            onChange={() =>
                                                                                this.handleRadio(
                                                                                    index,
                                                                                    'item_description_correct',
                                                                                    'N'
                                                                                )
                                                                            }
                                                                            disabled={isDisabled}
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                marginRight: '4px'
                                                                            }}>
                                                                            {lang.LABLES.No}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group radio-group">
                                                                    <span>
                                                                        {
                                                                            lang.LABLES
                                                                                .BINLABELEDCORRECT
                                                                        }{' '}
                                                                        {!!isSubmitClicked &&
                                                                            !data.bin_labeled_correct && (
                                                                                <span className="srv-validation-message">
                                                                                    *
                                                                                </span>
                                                                            )}
                                                                    </span>
                                                                    <div className="data-upload-col">
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            value="Y"
                                                                            style={{
                                                                                marginRight: '3px'
                                                                            }}
                                                                            checked={
                                                                                data.bin_labeled_correct ==
                                                                                'Y'
                                                                            }
                                                                            onChange={() =>
                                                                                this.handleRadio(
                                                                                    index,
                                                                                    'bin_labeled_correct',
                                                                                    'Y'
                                                                                )
                                                                            }
                                                                            disabled={isDisabled}
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                marginRight: '10px'
                                                                            }}>
                                                                            {lang.LABLES.Yes}
                                                                        </span>
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            value="N"
                                                                            style={{
                                                                                marginRight: '3px'
                                                                            }}
                                                                            checked={
                                                                                data.bin_labeled_correct ==
                                                                                'N'
                                                                            }
                                                                            onChange={() =>
                                                                                this.handleRadio(
                                                                                    index,
                                                                                    'bin_labeled_correct',
                                                                                    'N'
                                                                                )
                                                                            }
                                                                            disabled={isDisabled}
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                marginRight: '4px'
                                                                            }}>
                                                                            {lang.LABLES.No}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group radio-group">
                                                                    <span>
                                                                        {lang.LABLES.ACTUALQUANTITY}
                                                                    </span>
                                                                    <div className="data-upload-col">
                                                                        <input
                                                                            type="text"
                                                                            id={index}
                                                                            className={`form-control ${
                                                                                !!isSubmitClicked &&
                                                                                !data.actual_quantity
                                                                                    ? 'blank-field'
                                                                                    : ''
                                                                            }`}
                                                                            name="actual_quantity"
                                                                            value={
                                                                                data.actual_quantity
                                                                                    ? data.actual_quantity
                                                                                    : ''
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .restrictToNumber
                                                                            }
                                                                            disabled={isDisabled}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <button
                                                                    className="btn-prmry"
                                                                    onClick={() =>
                                                                        this.deleteCard(index, data)
                                                                    }>
                                                                    {lang.BUTTONS.Delete}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            })}
                                    </div>
                                </div>
                            )}
                            {currentTabName === 'PARTS ROOM SAFETY & SECURITY' && (
                                <div className="table-outer tabcontent active">
                                    {QuesList.map((data, index) => {
                                        if (data.long_heading === currentTabName) {
                                            return (
                                                // <div className={`data-upload-row ${!data.reviewer_remark ? ((!data.isInspected && !data.isApplicable) ?
                                                //   ((!data.url || data.url.length == 0) ? "missing-info" : "completed-info") : "completed-info") :
                                                // "reviewer-remark"}`} key={index}>
                                                <div className="data-upload-row" key={index}>
                                                    <div className="data-upload-col">
                                                        {data.long_ques}
                                                    </div>
                                                    <div className="data-upload-col">
                                                        {this.getSelectBox(index, data, 3)}
                                                    </div>
                                                    <div className="data-upload-col">
                                                        <div className="upload-file-control">
                                                            <input
                                                                type="file"
                                                                id={data.ques_id}
                                                                name={index}
                                                                disabled={isDisabled}
                                                                onChange={(event) =>
                                                                    this.openUploadModal(
                                                                        event,
                                                                        data,
                                                                        index
                                                                    )
                                                                }
                                                            />
                                                            <label
                                                                className={`form-control ${
                                                                    !data.isApplicable &&
                                                                    !!isSubmitClicked &&
                                                                    !data.url.length
                                                                        ? 'upload-blank-field'
                                                                        : !!data.isApplicable &&
                                                                          !!isSubmitClicked &&
                                                                          data.url.length == 0
                                                                        ? 'upload-blank-field'
                                                                        : ''
                                                                }`}
                                                                htmlFor={data.ques_id}
                                                                onClick={(event) =>
                                                                    this.openUploadModal(
                                                                        event,
                                                                        data,
                                                                        index
                                                                    )
                                                                }>
                                                                {lang.LABLES.UploadFile}
                                                            </label>
                                                        </div>
                                                        <div className="group-upload-images margin-bottom-10">
                                                            {data.url &&
                                                                data.url.map(
                                                                    (imgObj, imgIndex) =>
                                                                        imgObj.img_path &&
                                                                        (!imgObj.isVideo ? (
                                                                            <img
                                                                                key={imgIndex}
                                                                                src={
                                                                                    imgObj.img_path
                                                                                }
                                                                                alt="Images Icons"
                                                                                className="upload-images"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        task_status !=
                                                                                        3
                                                                                    ) {
                                                                                        this.showHideImageModel(
                                                                                            index,
                                                                                            imgObj,
                                                                                            imgIndex
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <div
                                                                                className="upload-images"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        task_status !=
                                                                                        3
                                                                                    ) {
                                                                                        this.showHideImageModel(
                                                                                            index,
                                                                                            imgObj,
                                                                                            imgIndex
                                                                                        );
                                                                                    }
                                                                                }}>
                                                                                <Thumbnail
                                                                                    key={imgIndex}
                                                                                    videoUrl={
                                                                                        imgObj.img_path
                                                                                    }
                                                                                    width={38}
                                                                                    height={38}
                                                                                />
                                                                            </div>
                                                                        ))
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div className="data-upload-col">
                                                        <textarea
                                                            className={`form-control ${
                                                                !!data.isApplicable &&
                                                                !!isSubmitClicked &&
                                                                !data.observation
                                                                    ? 'upload-blank-field'
                                                                    : ''
                                                            }`}
                                                            value={
                                                                data.observation
                                                                    ? data.observation
                                                                    : ''
                                                            }
                                                            placeholder={lang.LABLES.Observation}
                                                            id={index}
                                                            name="observation"
                                                            disabled={task_status == 3}
                                                            onChange={
                                                                this.handleChangeWithLength
                                                            }></textarea>
                                                    </div>
                                                    <div className="data-upload-col">
                                                        <textarea
                                                            className="form-control"
                                                            value={
                                                                data.auditor_comments
                                                                    ? data.auditor_comments
                                                                    : ''
                                                            }
                                                            placeholder={lang.LABLES.Impact}
                                                            id={index}
                                                            name="auditor_comments"
                                                            disabled={
                                                                task_status == 3 ||
                                                                !!data.isApplicable
                                                            }
                                                            onChange={
                                                                this.handleChangeWithLength
                                                            }></textarea>
                                                    </div>
                                                    <div className="data-upload-col">
                                                        <textarea
                                                            className="form-control"
                                                            value={
                                                                data.reviewer_comments
                                                                    ? data.reviewer_comments
                                                                    : ''
                                                            }
                                                            onChange={this.handleChangeWithLength}
                                                            id={index}
                                                            name="reviewer_comments"
                                                            disabled={
                                                                task_status == 3 ||
                                                                !!data.isApplicable
                                                            }
                                                            placeholder={
                                                                lang.LABLES.Recommendation
                                                            }></textarea>
                                                    </div>
                                                    {(task_status == 2 ||
                                                        ((task_status == 1 || task_status == 3) &&
                                                            data.reviewer_remark)) && (
                                                        <div className="data-upload-col">
                                                            <textarea
                                                                className="form-control"
                                                                value={
                                                                    data.reviewer_remark
                                                                        ? data.reviewer_remark
                                                                        : ''
                                                                }
                                                                id={index}
                                                                name="reviewer_remark"
                                                                onChange={
                                                                    this.handleChangeWithLength
                                                                }
                                                                disabled={
                                                                    task_status == 1 ||
                                                                    task_status == 3
                                                                }
                                                                placeholder={
                                                                    lang.LABLES.ReviewerComment
                                                                }></textarea>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <input
                        type="checkbox"
                        name="confirmation"
                        value={confirmation}
                        onChange={this.handleCheckBox}
                    />
                    <label htmlFor="confirmation"> {lang.STRINGS.CONFIRMATIONMESSAGE}</label>
                </div>
                {QuesList.length > 0 && task_status != 3 && (
                    <div className="submition-outer">
                        {/* {currentTabName === "ORACLE SYSTEM AUDIT" &&
               <input type="text" className="item-count-box" value={`${countObj.items_audited} / ${countObj.items_to_audit} / ${countObj.total_item_count}`} disabled />
               <span className="item-count-box">{`Filled: ${countObj.items_audited} | Req: ${countObj.items_to_audit} | Total: ${countObj.total_item_count}`}</span>
               <span className="item-count-box" disabled><abbr title="Number of audited items">{`${countObj.items_audited}`}</abbr>{` / `}<abbr title="Minimum number of items to audit">{`${countObj.items_to_audit}`}</abbr>{` / `}<abbr title="Total number of items">{`${countObj.total_item_count}`}</abbr></span>
             } */}
                        <button
                            className="btn-prmry"
                            onClick={() => {
                                this.submitAns(false);
                            }}>
                            {lang.BUTTONS.SavePreview}
                        </button>
                        <button className="btn-prmry" onClick={() => this.submitAns(true)}>
                            {lang.BUTTONS.Submit}
                        </button>
                    </div>
                )}
                {showImageModel && (
                    <ImagePrevew
                        isModalOpen={showImageModel}
                        imgObj={imgObj}
                        showDeleteButton={true}
                        onCancel={this.showHideImageModel}
                        deleteImage={this.deleteImage}
                        langData={this.props?.langData}
                    />
                )}
                {scan && (
                    <ScannerModule
                        isModalOpen={onBarcodeOpen}
                        startScanner={this.startScanner}
                        onCancel={this.onBarcodeCancel}
                        langData={this.props?.langData}
                    />
                )}
                {showPreviewModel && (
                    <PreviewModal
                        isModalOpen={showPreviewModel}
                        taskInfo={taskInfo}
                        onClose={this.showHidePreviewModel}
                        data={QuesList.filter(
                            (data) => data.long_heading === 'PARTS ROOM SAFETY & SECURITY'
                        )}
                        langData={this.props?.langData}
                    />
                )}
                {showMannualModel && (
                    <DataCardModal
                        isModalOpen={showMannualModel}
                        onCancel={() => this.showHideMannualCardModal('')}
                        assetTypeId={2}
                        taskInfo={taskInfo}
                        searchVal={searchVal}
                        addCard={this.addCard}
                        langData={this.props?.langData}
                    />
                )}
                {isQuantifiableModalOpen && (
                    <QuantifiableModal
                        isQuantifiableModalOpen={isQuantifiableModalOpen}
                        quesData={questionData}
                        index={index}
                        restrictNumber={this.restrictToNumber}
                        onClose={this.closeQuantifiableModal}
                        isApplicable={this.isApplicable}
                        handleChange={this.handleChange}
                        isInspected={this.isInspected}
                        selectedType={selectedType}
                        taskInfo={taskInfo}
                        langData={this.props?.langData}
                    />
                )}
                {isUploadFile && (
                    <ImageVideoSelectionModal
                        isModalOpen={isUploadFile}
                        onCancel={this.handleUploadModalClose}
                        handleFile={this.handleFile}
                        index={uploadObj.index}
                        data={uploadObj.data}
                        isSubmitClicked={isSubmitClicked}
                        langData={this.props?.langData}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        heading: state.common.get('prHeadingData'),
        prData: state.common.get('prData')
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getData: (url, data, type, loaderMsg, obj, language = 'en') =>
            dispatch(getPostDataWithType(url, data, type, loaderMsg, obj, language)),
        submitAns: (url, data, path, method, loaderMsg, obj, language = 'en') =>
            dispatch(getPostDataWithAction(url, data, path, method, loaderMsg, obj, language)),
        removeWOData: (type) =>
            dispatch({
                type,
                payload: []
            })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PRAuditorAnsComp);
